// index.js
import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import UploadResource from './uploadResource';
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import OrdersBagImg from "../../assets/images/orderbags.svg";
import ResourceList from './ResourceList';
import { useSelector, useDispatch } from "react-redux";
import { getResources } from '../../store/course/reducer';
import { PulseLoader } from 'react-spinners';

const AddResources = () => {
    const dispatch = useDispatch();
    const { fetchedCourse, resources, loading } = useSelector((state) => ({
        fetchedCourse: state.course.selected,
        resources: state.course.resources,
        loading: state.course.isFetching,
    }));

    const [resourceList, setResourceList] = useState([]);

    useEffect(() => {
        if (resources?.documents) {
            setResourceList(resources.documents);
        }
    }, [resources]);

    useEffect(() => {
        if (fetchedCourse) {
            dispatch(getResources({ courseId: fetchedCourse._id }));
        }
    }, [dispatch, fetchedCourse]);

    return (
        <div className='page-content'>
            <h4 className='mx-3 my-3'>Add Resources for {fetchedCourse?.title}</h4>

            <Row className='d-flex flex-column'>
                <Col xl={5}>
                    <div className='p-3'>
                            <UploadResource courseId={fetchedCourse?._id} />
                    </div>
                </Col>
                <Col xl={4}>
                    {loading ? <PulseLoader color="#5156be" size={10} /> :
                        <>
                            {resourceList.length === 0 ?
                                <Empty
                                    img={OrdersBagImg}
                                    title="No Resource Added"
                                    msg="You do not have any resource for this course"
                                /> : <ResourceList workbooks={resourceList} courseId={fetchedCourse?._id} />}
                        </>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default AddResources;
