import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Container, Row, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import OrdersBagImg from "../../assets/images/orderbags.svg"


import CourseCard from './CourseCard';
import { useHistory, Link } from 'react-router-dom';
import { deleteCourse, getCourse, getCourses, removeTitle, setTitle } from '../../store/course/reducer';
import { useDispatch, useSelector } from "react-redux";
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import { Skeleton } from '../../components/CommonForBoth/Stoched/Skeleton';
import ReactPaginate from "react-paginate";
import debounce from "lodash.debounce";
import { AddCourseIcon } from '../../assets/icons/Custom';

const index = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { fetchedCourses, loading } = useSelector(state => ({
        fetchedCourses: state.course.courses,
        loading: state.course.isLoading,
      }))

    const [editingCourse, setEditingCourse] = useState(null);
    // const [courses, setCourses] = useState([])

    // const [currentPage, setCurrentPage] = useState(1);
    
    // const [searchTerm, setSearchTerm] = useState('')

    // const [coursesPerPage] = useState(10);

    const [courses, setCourses] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [pageNumber, setPageNumber] = useState(0);

    //Pagination
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;

    const pageCount = Math.ceil(fetchedCourses.total / usersPerPage);

    const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    };


    //End Pagination

    useEffect(() => {
        // Simulating the API call to fetch the data

        const query = {
            'pageNumber': pageNumber,
            'searchTerm': searchValue,
        }
        dispatch(getCourses(query))
    }, [pageNumber, searchValue]);


    useEffect(() => {
        dispatch(removeTitle())
        dispatch(setTitle(`My Courses (${fetchedCourses.total})`))
    }, [])

     useEffect(() => {
        setCourses(fetchedCourses.courses)
    }, [fetchedCourses])

    function handleResource(course) {
        history.push(`/add-resources/${course._id}`)
        // dispatch(getCourse(course._id))
    }

    function handleEdit(course) {
        console.log('Selected Course', course._id)

        dispatch(getCourse(course._id))
        history.push(`/update-course/${course._id}`)
    }

    function handleDelete(id) {

        // Remove the course from the array of courses
        const updatedCourses = courses.filter(course => course._id !== id);
        // Update the state with the new array of courses
        setCourses(updatedCourses);

        dispatch(deleteCourse(id))
    }

    const sizes = [1,2,3,4]

     //Search

    // Debounced function to handle search submission
    const debouncedSearch = debounce((searchQuery) => {
        // Perform the actual search operation here
        // console.log("Performing search with query:", searchQuery);
    }, 500); // Debounce delay set to 500 milliseconds

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);

        // Trigger the debounced search function with the updated search value
        debouncedSearch(value);
    };


  return (
    <div className="page-content">
        <Container fluid>

            <Row className="mt-2">

                <Col xl={11} xs={12}>

                    <div className='d-flex justify-content-between'>                
                        <div className='me-2 my-3'>
                            <form className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input type="text" className="form-control" placeholder="Search available courses..."
                                        value={searchValue}
                                        onChange={handleSearchChange} />
                                    <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
                                </div>
                            </form> 
                        </div>
                        
                        <div className='mt-4'>
                            <Link to="/create-course">
                                <button className="btn btn-primary btn-rounded waves-effect waves-light"
                                    type="button"
                                >
                                    <AddCourseIcon color="#fff"/>
                                    Create Course
                                </button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mt-2">

                <Col xl={11} xs={12}>

                {loading ? (
                    <Row>
                        {sizes.map((size, i) => (
                            <Col xl={3} lg={4} sm={6} key={i}>
                                <Skeleton />
                            </Col>
                        ))}
                    </Row>
                    ) : courses?.length === 0 ? (
                    <div className='my-5'>
                        <Empty
                            img={OrdersBagImg}
                            title="No Course Available"
                            msg="Your courses will be displayed in this section"
                        />
                    </div>
                    ) : (
                        <Row>
                            {courses?.map((course, i) => 
                                <Col xl={3} lg={4} sm={6} key={course._id}>
                                    <CourseCard 
                                        course={course}
                                        onAdd={() => handleResource(course)}
                                        onEdit={() => handleEdit(course)}
                                        onDelete={handleDelete}/>
                                </Col>
                            )}
                        </Row>
                    )}
                </Col>
            </Row>

             {/* Pagination */}
        <div className='mt-4'>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
            />
        </div>

        </Container>
           
       
      
    </div>
  )
}

export default index
