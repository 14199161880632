import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Profile from "./auth/profile/reducer"

import { loginSlice } from "./auths/login/reducer"
import { registerSlice } from "./auths/register/reducer"
import { emailConfirmationSlice } from "./auths/emailConfirmation/reducer"
import { logoutSlice } from "./auths/logout/reducer"
import { forgotPasswordSlice } from "./auths/forgotPassword/reducer"
import { resetPasswordSlice } from "./auths/resetPassword/reducer"
import { courseSlice } from "./course/reducer"
import { profileSlice } from "./auths/profile/reducer"

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const loginPersistConfig = {
  key: "login",
  storage,
  whitelist: ['user', 'stats', 'active'],
  blacklist: ['isLoading', 'isRefreshing']
}

const logoutPersistConfig = {
  key: "logout",
  storage,
  blacklist: ['isLoading']
}

const coursesPersistConfig = {
  key: "course",
  storage,
  whitelist: ['courses', 'enrolled', 'filteredEnrolled'],
  blacklist: ['resourceUploadResponse', 'deleteResponse', 'isDeleting', 'deleted', 'selected'],
}

const rootReducer = combineReducers({
  // public

  Layout,
  Profile,
  login: persistReducer(loginPersistConfig, loginSlice.reducer),
  register: registerSlice.reducer,
  emailConfirmation: emailConfirmationSlice.reducer,
  logout: persistReducer(logoutPersistConfig, logoutSlice.reducer),
  forgotPassword: forgotPasswordSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  profile: profileSlice.reducer,
  
  //Course
  course: persistReducer(coursesPersistConfig, courseSlice.reducer),
})

export default rootReducer
